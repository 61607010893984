import React from 'react';
import { Avatar, Menu, MenuItem, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { UserIdentity } from 'components/userIdentityInfo';
import { PUITooltip } from 'shared-components/PUITooltip';
import { PUIIconButton } from 'shared-components/PUIIconButton';

export const getUserInitials = (user?: UserIdentity | null) => {
  if (!user) return null;
  return [
    user.company_key?.charAt(0).toUpperCase(),
    user.user_email?.charAt(0).toUpperCase(),
  ].filter((elem) => typeof elem === 'string')[0];
};

export type AccountSettingsMenuProps = {
  userIdentity?: UserIdentity | null;
  onLogout?: () => void;
};

export const AccountSettingsMenu = ({
  userIdentity,
  onLogout: logout,
}: AccountSettingsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PUITooltip title="Account settings">
        <PUIIconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar>
            <PersonIcon />
          </Avatar>
        </PUIIconButton>
      </PUITooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem disabled>
          {(userIdentity?.user_email || userIdentity?.company_key) ??
            'Unknown Company'}
        </MenuItem>
        <Divider />
        {/* TODO: uncomment this menu item after we create the user account page */}
        {/* <Link
          href="/account"
          passHref
          css={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: 'pointer',
          }}
        >
          <MenuItem>Your Account</MenuItem>
        </Link> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
