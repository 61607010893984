import { CSSObject, useTheme } from '@emotion/react';
import { AccountSettingsMenu } from 'components/AccountSettingsMenu';
import { useApiClient } from 'components/ApiClientProvider';
import { useRouter } from 'next/router';
import {
  ForwardedRef,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useCallback,
} from 'react';
import {
  PUIDivider,
  PUIIconButton,
  PUIParadeLogo,
  PUITooltip,
} from 'shared-components';
import { PUINavBar, PUIToolbar } from 'shared-components/PUINavBar';
import {
  AttractionsIcon,
  BatchPredictionIcon,
  LaneSearchIcon,
  RatingAnalyticsIcon,
  RatingConfigIcon,
  CoDriverIcon,
} from 'shared-components/PUIIcons';
import { PageTitle } from 'components/PageTitle';
import { FeatureFlagIt } from 'components/FeatureFlagIt';

export const NAV_BAR_WIDTH = '5rem';

export enum PAGES {
  HOME = '/',
  BATCH_PRICING = '/batch-pricing',
  BID_CONFIGURATION = '/bid-configuration',
  BID_ANALYTICS = '/bid-analytics',
  CODRIVER = '/codriver',
  REPORTING_SANDBOX = '/reporting-sandbox',
}

const sideNavBarToolbarsWrapperStyle: CSSObject = {
  flex: '1 1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
};

export function isButtonActive(buttonPath: string): string {
  return RegExp(buttonPath).exec(location.pathname) ? 'active' : '';
}

export interface BaseLayoutProps extends PropsWithChildren {
  aside?: ReactNode;
  onToolbarClick?: (event: SyntheticEvent) => void;
  toolbarPosition?: 'sticky' | 'fixed' | 'static';
  navBarRef?: ForwardedRef<HTMLDivElement>;
  title?: string;
}

export const BaseLayout = ({ children, aside, title }: BaseLayoutProps) => {
  const theme = useTheme();
  const router = useRouter();
  const {
    session: {
      user: { identity: userIdentity },
      logout,
    },
  } = useApiClient();

  const logoutHandler = useCallback(() => logout(false), [logout]);

  const buildPageClickHandler = useCallback(
    (route: string) => () => {
      router.push(route).catch((error) => {
        console.error(error);
      });
    },
    [router]
  );

  const sideNavToolbarStyle: CSSObject = {
    flexDirection: 'column',
    padding: `${theme.spacing(3)} 0`,
  };

  const sideNavActiveButtonStyle: CSSObject = {
    '&.active': {
      backgroundColor: theme.palette.neutral.dark,
      borderRadius: '5%',
    },
  };

  return (
    <>
      <PageTitle optionalTitle={title} />
      <div
        css={{
          display: 'flex',
        }}
      >
        <PUINavBar
          color="secondary"
          position="fixed"
          vertical
          toolbarContent={
            <>
              {/* Go to Index page button with Parade Logo */}
              <PUIToolbar>
                <PUIIconButton
                  onClick={buildPageClickHandler(PAGES.HOME)}
                  size="large"
                  color="inherit"
                  aria-label="Main"
                >
                  <PUIParadeLogo size="small" />
                </PUIIconButton>
              </PUIToolbar>

              <PUIDivider orientation={'horizontal'} flexItem />

              {/* Main Navigation Buttons */}
              <div css={sideNavBarToolbarsWrapperStyle}>
                <PUIToolbar
                  css={sideNavToolbarStyle}
                  disableGutters
                  role="navigation"
                >
                  <PUITooltip title="Lane Search">
                    <PUIIconButton
                      onClick={buildPageClickHandler(PAGES.HOME)}
                      size="large"
                      color="inherit"
                      css={sideNavActiveButtonStyle}
                      className={isButtonActive('(^/$)|(/advantage)')}
                      aria-label="Advantage Dashboard"
                    >
                      <LaneSearchIcon height={36} width={36} />
                    </PUIIconButton>
                  </PUITooltip>

                  <PUITooltip title="Batch Price Prediction">
                    <PUIIconButton
                      onClick={buildPageClickHandler(PAGES.BATCH_PRICING)}
                      size="large"
                      color="inherit"
                      css={sideNavActiveButtonStyle}
                      className={isButtonActive(PAGES.BATCH_PRICING)}
                      aria-label="Batch Pricing"
                    >
                      <BatchPredictionIcon height={36} width={36} />
                    </PUIIconButton>
                  </PUITooltip>

                  <FeatureFlagIt id="show-real-time-rating-rules-configuration">
                    <PUITooltip title="Real Time Rating Configuration">
                      <PUIIconButton
                        onClick={buildPageClickHandler(PAGES.BID_CONFIGURATION)}
                        size="large"
                        color="inherit"
                        css={sideNavActiveButtonStyle}
                        className={isButtonActive(PAGES.BID_CONFIGURATION)}
                        aria-label="Real Time Rating Configuration"
                      >
                        <RatingConfigIcon height={36} width={36} />
                      </PUIIconButton>
                    </PUITooltip>
                  </FeatureFlagIt>

                  <FeatureFlagIt id="show-real-time-rating-rules-analytics">
                    <PUITooltip title="Bid Analytics">
                      <PUIIconButton
                        onClick={buildPageClickHandler(PAGES.BID_ANALYTICS)}
                        size="large"
                        color="inherit"
                        css={sideNavActiveButtonStyle}
                        className={isButtonActive(PAGES.BID_ANALYTICS)}
                        aria-label="Bid Analytics"
                      >
                        <RatingAnalyticsIcon height={36} width={36} />
                      </PUIIconButton>
                    </PUITooltip>
                  </FeatureFlagIt>

                  <FeatureFlagIt id="show-codriver">
                    <PUITooltip title="CoDriver Opportunities">
                      <PUIIconButton
                        onClick={buildPageClickHandler(PAGES.CODRIVER)}
                        size="large"
                        color="inherit"
                        css={sideNavActiveButtonStyle}
                        className={isButtonActive(PAGES.CODRIVER)}
                        aria-label="CoDriver Opportunities"
                      >
                        <CoDriverIcon height={36} width={36} />
                      </PUIIconButton>
                    </PUITooltip>
                  </FeatureFlagIt>

                  <PUITooltip title="Reporting Sandbox">
                    <PUIIconButton
                      onClick={buildPageClickHandler(PAGES.REPORTING_SANDBOX)}
                      size="large"
                      color="inherit"
                      css={sideNavActiveButtonStyle}
                      className={isButtonActive(PAGES.REPORTING_SANDBOX)}
                      aria-label="Reporting Sandbox"
                    >
                      <AttractionsIcon height={36} width={36} />
                    </PUIIconButton>
                  </PUITooltip>
                </PUIToolbar>

                {/* User Account Button */}
                <div>
                  <PUIDivider orientation={'horizontal'} flexItem />
                  <PUIToolbar css={sideNavToolbarStyle} disableGutters>
                    <AccountSettingsMenu
                      userIdentity={userIdentity}
                      onLogout={logoutHandler}
                    />
                  </PUIToolbar>
                </div>
              </div>
            </>
          }
          css={{
            width: NAV_BAR_WIDTH,
            height: '100vh',
            left: 0,
            alignItems: 'center',
            zIndex: 1250,
          }}
        />
        <div css={{ width: '100%', paddingLeft: NAV_BAR_WIDTH }}>
          {children}
        </div>
        {aside}
      </div>
    </>
  );
};
