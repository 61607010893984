import { useFeatureFlags } from 'components/FeatureFlagsProvider';
import { defaultFeatureFlags } from 'config/defaultFeatureFlags';
import { PropsWithChildren } from 'react';

export type FeatureFlagItProps = {
  id: keyof typeof defaultFeatureFlags;
} & PropsWithChildren;

export const FeatureFlagIt = ({ id, children }: FeatureFlagItProps) => {
  const featureFlags = useFeatureFlags();
  return <>{featureFlags[id] && children}</>;
};
